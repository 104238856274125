const History = () => {
  return (
    <main className='history'>
      <section className='history-main'>
        {" "}
        <div className='history-main-img'>
          <img src='./assets/images/notrehistoire.png' alt='Notre histoire' />
        </div>
      </section>
      <section className='history-container'>
        <div className='history-container-images'>
          <div className='history-container-images-1'></div>
          <div className='history-container-images-2-3'>
            <div className='history-container-images-2'></div>
            <div className='history-container-images-3'></div>
          </div>
        </div>
        <div className='history-container-text'>
          <p className='history-container-paragraph'>
            Notre histoire d'amour a commencé il y a quelques années dans un bar
            à Calvi, le bar de la tour. Nous nous sommes revus par hasard en
            face de la biscuiterie où Cécilia allait chercher son petit frère à
            l'école. Après de nombreuses soirées passées à parler et à rire tout
            simplement sur la place de l'église, nous sommes devenus amis et
            avons finalement réalisé que nous étions tombés amoureux l'un de
            l'autre en seulement 10 jours. Vivre ensemble était une évidence
            pour nous, alors nous sommes partis en vacances pour le nouvel an et
            sommes tombés amoureux d'une ville ensemble. Nous avons parcouru
            plus de 1000 kilomètres pour y vivre et avons rencontré des
            personnes formidables, tout en gardant le contact avec nos amis et
            notre famille. Nous avons créé une famille ensemble, même si cela
            n'était pas prémédité, mais totalement assumé. Nous avons ri
            ensemble, partagé des passions des petites voitures à la pêche en
            passant par la Bachata, que ce soit à deux ou seul, cela n'a pas
            d'importance, car nous ne faisons qu'un. Nous avons déménagé de
            nombreuses fois, acheté une maison et créé une nouvelle petite
            chose. Après tant d'attente, nous avons finalement célébré nos
            fiançailles, vendu notre maison pour tout recommencer et sommes
            repartis à l'aventure dans une nouvelle ville sans rien laisser
            derrière nous. Nous sommes reconnaissants pour tout ce que nous
            avons accompli ensemble et nous savons que c'est grâce à « vous »
            qu'il y a un "nous". Alors avant tout, merci à vous tous. De venir
            célébrer ce jour avec nous et dêtre présent un peu, beaucoup ou
            passionnément.
          </p>
        </div>
      </section>
    </main>
  );
};

export default History;
