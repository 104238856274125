import { useState } from "react";
import data from "../data/database.json";
import Activity from "./Activity";

const Favorite = () => {
  const [selectedVille, setSelectedVille] = useState("");
  const [activities, setActivities] = useState<string[]>([]);
  const [baignade, setBaignade] = useState<string[]>([]);
  const [boutiques, setBoutiques] = useState<string[]>([]);
  const [food, setFood] = useState<string[]>([]);

  const handleChange = (e: any) => {
    setSelectedVille(e.target.value);

    showCitiesActivities(e.target.value);
  };

  const showCitiesActivities = (city: string) => {
    const selectedCity = data.favorites.find((item) => item.ville === city);

    if (selectedCity) {
      selectedCity.activities.length > 0
        ? setActivities(selectedCity.activities)
        : setActivities([]);
      selectedCity.plageRiver.length > 0
        ? setBaignade(selectedCity.plageRiver)
        : setBaignade([]);
      selectedCity.boutiques.length > 0
        ? setBoutiques(selectedCity.boutiques)
        : setBoutiques([]);
      selectedCity.foodDrinks.length > 0
        ? setFood(selectedCity.foodDrinks)
        : setFood([]);
    } else {
      setFood([]);
      setBoutiques([]);
      setBaignade([]);
      setActivities([]);
    }
  };

  return (
    <div className='more-favorite-content'>
      <div className='more-favorite-search'>
        <select id='ville' value={selectedVille} onChange={handleChange}>
          <option value=''>Choisissez une ville</option>
          {data.favorites.map((item, index) => (
            <option key={index} value={item.ville}>
              {item.ville}
            </option>
          ))}
        </select>
      </div>
      <div className='more-favorite-results'>
        {activities.length > 0 && (
          <Activity items={activities} title='Activités' />
        )}
        {baignade.length > 0 && <Activity items={baignade} title='Baignades' />}
        {boutiques.length > 0 && (
          <Activity items={boutiques} title='Boutiques' />
        )}
        {food.length > 0 && <Activity items={food} title='Restaurant & co' />}
      </div>
    </div>
  );
};

export default Favorite;
