export const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const searchNames = (
  search: string,
  groupes: {
    names: string;
    groupes: number[];
  }[]
) => {
  let nameFind = [];

  for (let i = 0; i < groupes.length; i++) {
    if (
      removeAccents(groupes[i].names.toLocaleLowerCase()).startsWith(
        removeAccents(search.toLocaleLowerCase())
      )
    ) {
      nameFind.push(groupes[i].names);
    }
  }
  return nameFind.sort();
};

// export const searchNames = (
//   search: string,
//   groupes: {
//     names: string;
//     groupes: number[];
//   }[]
// ) => {
//   let nameFind = [];

//   for (let i = 0; i < groupes.length; i++) {
//     if (
//       groupes[i].names
//         .toLocaleLowerCase()
//         .startsWith(search.toLocaleLowerCase())
//     ) {
//       nameFind.push(groupes[i].names);
//     }
//   }
//   return nameFind.sort();
// };

export const searchGroupes = (
  search: string,
  groupes: {
    names: string;
    groupes: number[];
  }[]
) => {
  let groupesFind: number[] = [];

  for (let i = 0; i < groupes.length; i++) {
    console.log(search);
    if (groupes[i].names.toLocaleLowerCase() === search.toLocaleLowerCase()) {
      groupesFind = groupes[i].groupes;
    }
  }
  return groupesFind;
};

export const majusculePremiereLettre = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getNamesGroupes = (
  numberGroupe: number,
  groupes: {
    names: string;
    groupes: number[];
  }[]
) => {
  let namesGroupe = [];

  for (let i = 0; i < groupes.length; i++) {
    if (groupes[i].groupes.includes(numberGroupe)) {
      switch (groupes[i].names) {
        case "Béatrice Massicot":
        case "Isabelle Flores":
        case "Denis Flores":
        case "Hadrien Flores":
        case "Esther Diverchy":
          break;

        default:
          namesGroupe.push(groupes[i].names);
          break;
      }
    }
  }

  return namesGroupe.length === groupes.length - 5
    ? ["Tout le monde !"]
    : namesGroupe.sort();
};
