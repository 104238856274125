import { useState } from "react";

interface legendeItemInt {
  icone: string;
  text: string;
  adresse?: boolean;
}
const LegendeItem = ({ icone, text, adresse }: legendeItemInt) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        text
      )}`;
      window.open(url, "_blank");

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Erreur lors de la copie,err");
    }
  };
  return (
    <li>
      <i className={icone}></i>
      {adresse ? (
        <p className='cursor' onClick={handleCopyClick}>
          {text}
          {copied && <span className='tooltip'>Copié !</span>}
        </p>
      ) : (
        <p>{text}</p>
      )}
    </li>
  );
};

export default LegendeItem;
