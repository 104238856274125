import React from "react";

interface bandeauPartieInt {
  text: string;
  textId?: string;
}

const BandeauPartie = ({ text, textId }: bandeauPartieInt) => {
  if (text === "Se repérer") {
    return (
      <div className='organisation-bandeau' id={textId}>
        <p className='organisation-bandeau-text'>{text}</p>
        <p className='organisation-bandeau-plus'>(Adresses cliquables)</p>
      </div>
    );
  } else {
    return (
      <div className='organisation-bandeau' id={textId}>
        <p className='organisation-bandeau-text'>{text}</p>
      </div>
    );
  }
};

export default BandeauPartie;
