import React from "react";
interface activityInt {
  items: string[];
  title: string;
}
const Activity = ({ items, title }: activityInt) => {
  return (
    <div className='activities'>
      <h3 className='activities-title'>{title}</h3>
      <ul className='activities-list'>
        {items.map((activity, id) => {
          console.log(activity);
          return (
            <li key={id} className='activities-item'>
              {activity}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Activity;
