import { useEffect, useRef, useState } from "react";
import data from "../data/database.json";
import {
  getNamesGroupes,
  majusculePremiereLettre,
  searchGroupes,
  searchNames,
} from "../utils";

const GroupesPhotos = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeButton, setActiveButton] = useState(-1);
  const [openList, setOpenList] = useState(false);
  const [namesFind, setNamesFind] = useState<string[]>([]);
  const [groupesFind, setGroupesFind] = useState<number[]>([]);
  const [placeholderInput, setPlaceholderInputInput] = useState(
    "Taper 3 lettres minimum"
  );
  const [valueInput, setValueInput] = useState("");
  const [close, setClose] = useState(false);
  const [namesGroupe, setNamesGroupe] = useState<string[]>([]);

  const reinitialise = () => {
    setOpenList(false);
    setNamesFind([]);
    setGroupesFind([]);
    setActiveButton(-1);
    setPlaceholderInputInput("Taper 3 lettres minimum");
    setNamesGroupe([]);
  };

  const handleKeyUp = (e: any) => {
    e.preventDefault();
    if (e.key === "Enter" && openList) {
      const inputValue = majusculePremiereLettre(e.target.value);
      setValueInput(inputValue);

      if (inputValue.length > 2 && namesFind.length > 0) {
        handleChooseName(e, namesFind[0]);
      } else {
        reinitialise();
      }
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const inputValue = majusculePremiereLettre(e.target.value);
    setValueInput(inputValue);

    if (inputValue.length > 2) {
      setNamesFind(searchNames(inputValue, data.groupes));
      setPlaceholderInputInput(inputValue);
      setOpenList(true);
    } else {
      reinitialise();
    }
  };

  const handleChooseName = (e: any, item: string) => {
    e.preventDefault();
    setOpenList(false);
    setGroupesFind(searchGroupes(item, data.groupes));
    setValueInput(item);
    setClose(true);
  };

  const resetName = () => {
    reinitialise();
    setClose(false);
    setValueInput("");
  };

  const handleCLickGroupe = (e: any, number: number) => {
    setNamesGroupe(getNamesGroupes(number, data.groupes));
    setActiveButton(number); // Mettre à jour le bouton actif
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='organisation-groupes-body'>
      <div className='organisation-groupes-content'>
        <div className='organisation-groupes-content-text'>
          Comme dans tout bon mariage, nous prendrons du temps pour réaliser des
          photos de groupe vers 19h30. Pour{" "}
          <span className='color-bordeaux'>faciliter </span>
          ce moment et qu'il ne dure{" "}
          <span className='color-bordeaux'>pas trop longtemps</span>,
          <p className='organisation-groupes-content-message'>
            <span className='warning'>
              {" "}
              Merci de prendre connaissance des numéros de groupe dont vous
              faites partis.
            </span>
            <br />
            Ne vous inquietez pas on vous les rappelera au moment où vous devrez
            faire vos plus beaux sourires
            <br />
            Le photographe sera avec nous tout au long de la soirée pour faire
            d'autres photos. N'hésitez pas à lui demander.
          </p>
        </div>
      </div>
      <div className='organisation-groupes-research'>
        <img
          src={data.programmes[3].img}
          alt="appareil d'un photographe"
          className='organisation-groupes-research-img'
        />
        <div className='organisation-groupes-research-bar'>
          <p className='organisation-groupes-research-title'>
            Chercher votre prénom :{" "}
          </p>
          <div className='relative'>
            <input
              type='text'
              className='organisation-groupes-research-search'
              onChange={(e) => handleChange(e)}
              placeholder={placeholderInput}
              value={valueInput}
              onKeyUp={handleKeyUp}
            />
            {openList && (
              <div
                className='absolute organisation-groupes-research-names'
                ref={dropdownRef}>
                {namesFind &&
                  namesFind.map((item, id) => (
                    <p
                      key={id}
                      onClick={(e) => handleChooseName(e, item)}
                      className='organisation-groupes-research-name'>
                      {item}
                    </p>
                  ))}
              </div>
            )}
            {close && (
              <button onClick={resetName} className='boutonClose'>
                x
              </button>
            )}
          </div>
        </div>
        {groupesFind && groupesFind.length > 0 && (
          <div className='organisation-groupes-research-result'>
            <p className='organisation-groupes-research-numberText'>
              Voici vos groupes, découvrez vos partenaires de sourire en
              cliquant dessus :{" "}
            </p>
            <div className='organisation-groupes-research-numbers'>
              {groupesFind.map((number, id) => {
                return (
                  <button
                    onClick={(e) => handleCLickGroupe(e, number)}
                    key={id}
                    className={`organisation-groupes-research-number ${
                      number === activeButton ? "buttonActive" : ""
                    }`}>
                    {" "}
                    {number}{" "}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className='organisation-groupes-groupe'>
        <ul>
          {namesGroupe &&
            namesGroupe.map((name, id) => {
              return <li key={id}>{name}</li>;
            })}
        </ul>
      </div>
    </div>
  );
};

export default GroupesPhotos;
