import BandeauPartie from "../components/BandeauPartie";
import GroupesPhotos from "../components/GroupesPhotos";
import ProgrammeCard from "../components/ProgrammeCard";
import SectionReperer from "../components/SectionReperer";
import data from "../data/database.json";

const Organisation = () => {
  return (
    <main className='organisation'>
      <section className='organisation-main'>
        <img
          src='./assets/images/text-orga.png'
          className='organisation-main-img'
          alt='L organisation'
        />
      </section>{" "}
      <BandeauPartie text='Dress Code' textId='dressCode' />
      <section className='organisation-dressCode'>
        <div className='organisation-dressCode-content'>
          <p>Bonjour à tous nos invités, </p>
          <p>
            Nous sommes très heureux de vous accueillir à notre mariage et nous
            sommes impatients de partager ce jour spécial avec vous.
            <br />
            Nous souhaitons vous informer qu'il y a un code vestimentaire et
            nous aimerions que vous en teniez compte afin que tout le monde soit
            à l'aise.
            <br />
            <br /> Nous vous demandons de porter{" "}
            <span className='color-bordeaux'>une tenue adaptée</span> à
            l'occasion.
            <br />
            Comme il fera chaud, nous vous conseillons de porter{" "}
            <span className='color-bordeaux'>
              des vêtements légers et confortables
            </span>
            .
            <br /> Veuillez également noter que le cocktail se tiendra sur de la
            pelouse, pensez donc à porter des chaussures appropriées pour éviter
            de vous enfoncer dans le sol (ou des protège-talons).
            <br />
            <br />
            <span className='warning'>
              Nos témoins seront habillés en bordeaux et les demoiselles
              d'honneur en rose clair. Nous vous demandons donc de ne pas porter
              ces couleurs afin que nous puissions tous nous démarquer les uns
              des autres.
            </span>
            <br />
            Nous sommes impatients de vous voir et de célébrer avec vous cette
            journée si spéciale. <br />
            <br />
            Merci d'avance pour votre compréhension.{" "}
          </p>
          <br />
          <p>Alexandre et Cécilia</p>
        </div>
      </section>
      <BandeauPartie text='Groupes photo' textId='groupePhoto' />
      <section className='organisation-groupes'>
        <GroupesPhotos />
      </section>
      <BandeauPartie text='Programme' textId='programme' />
      <section className='organisation-programme'>
        <ul className='organisation-programme-list'>
          {data.programmes.map((item) => {
            return (
              <li className='organisation-programme-item' key={item.id}>
                <ProgrammeCard
                  img={item.img}
                  hour={item.hours}
                  text={item.text}
                  name={item.name}
                  textPlus={item.textPlus}
                />
              </li>
            );
          })}
        </ul>
      </section>
      <BandeauPartie text='Se repérer' textId='seReperer' />
      {data.reperer.map((item) => {
        return (
          <section className='organisation-reperer' key={item.id}>
            <SectionReperer
              title={item.title}
              direction={item.direction}
              img={item.img}
              legendes={item.legendes}
            />
          </section>
        );
      })}
      <BandeauPartie text='Urne de mariage' textId='urne' />
      <section className='organisation-urne'>
        <div className='organisation-urne-content'>
          <p>
            Pour les personnes qui nous l'ont demandés,
            <br />
            nous n'avons pas prévu de liste de mariage <br />
            mais une urne sera sur place pour ceux qui veulent.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Organisation;
